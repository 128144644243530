<template>
  <div class="text-container">
    Eine funktionell optimierte und gut gestaltete Einrichtung Ihrer Offizin ist
    das Aushängeschild Ihrer Apotheke. Hierfür gibt es keine Standardlösung,
    sondern nur die Einrichtung, die zu Ihnen und Ihrem Standort passt. Hier
    arbeiten wir mit mehreren Einrichtern zusammen und schaffen eine Offizin, in
    der sich Ihre Kunden wohl fühlen und wo Ihnen die täglichen Aufgaben gut von
    der Hand gehen.
  </div>
</template>

<script>
export default {
  metaInfo: {
    meta: [
      {
        name: "description",
        content:
          "Wir beraten Sie bei der Einrichtung Ihrer Apotheken. Die funktionell optimierte und gut gestaltete Einrichtung ist das Aushängeschild Ihrer Apotheke.",
      },
    ],
  },
};
</script>